



















































import { defineComponent, Ref, onBeforeMount } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { FirstStep, SecondStep } from '@/inc/store/modules/steps'
import { StorageUtils } from '@/inc/plugins/storage'

import ContactSteps from '@/components/contact/Steps.vue'
import PartnerStep0 from '@/components/partner/Step0.vue'
import ContactLocation from '@/components/contact/Location.vue'
import ContactStep2 from '@/components/contact/Step2.vue'
import ContactStep3 from '@/components/contact/Step3.vue'

export default defineComponent({
  name: 'Partner-step',

  components: {
    ContactSteps,
    PartnerStep0,
    ContactLocation,
    ContactStep2,
    ContactStep3,
  },

  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const { $router, $route } = ctx.root
    const template = $route.name

    const { firstStep, secondStep } = useGetters('steps', [
      'firstStep',
      'secondStep',
    ]) as {
      firstStep: Ref<(storage: StorageUtils) => FirstStep>
      secondStep: Ref<(storage: StorageUtils) => SecondStep>
    }

    onBeforeMount(() => {
      if (!$route.params.step) {
        return
      }

      const routeStep = parseInt($route.params.step, 10)
      const step1 = firstStep.value(ctx.root.$options.$storage as StorageUtils)
      const step2 = secondStep.value(ctx.root.$options.$storage as StorageUtils)
      let currentStep = routeStep

      if (routeStep >= 2 && !step1) {
        currentStep = 1
      }

      if (routeStep === 3 && !step2) {
        currentStep = 2
      }

      if (routeStep > 3) {
        currentStep = 1
      }

      if (routeStep !== currentStep) {
        $router.replace({
          name: `partner-step${currentStep}`,
          params: { ...$route.params, step: `${currentStep}` },
        })
      }
    })

    return {
      content,
      template,
    }
  },
})
