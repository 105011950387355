var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{staticClass:"contact-form pb-xxl"},[_c('h2',{staticClass:"contact-form__title h3",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_c('h3',{staticClass:"contact-form__subtitle",domProps:{"innerHTML":_vm._s(_vm.content.subtitle)}}),_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var touched = ref.touched;
var invalid = ref.invalid;
return [_c('form',{ref:"form",staticClass:"form",attrs:{"method":"POST","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-inner"},[_c('fieldset',{staticClass:"form__fieldset"},[_vm._l((_vm.content.inputs),function(item){return _c('form-input',{key:("form-input-" + (item.name)),staticClass:"form__input",class:{ full: item.modifiers === 'full' },attrs:{"type":item.type,"name":item.name,"label":item.label,"placeholder":item.placeholder,"required":item.required}})}),(_vm.content.accessCode.slug == 'input')?_c('form-input',{staticClass:"form__input full",attrs:{"type":_vm.content.accessCode.type,"name":_vm.content.accessCode.name,"label":_vm.content.accessCode.label,"required":_vm.content.accessCode.required,"acceptedValues":Array.from(_vm.content.codes, function (item) { return item.code; })}}):_vm._e()],2),_c('fieldset',{staticClass:"form__send"},[_c('g-action',{staticClass:"submit__btn",class:{ sending: _vm.sending },attrs:{"content":{
              label: _vm.content.submit.label,
              icon: _vm.content.submit.icon,
              type: 'submit',
              tag: 'button',
              modifiers: ['filled', 'btn'],
            }},nativeOn:{"click":function($event){return _vm.onSubmitClick.apply(null, arguments)}}}),(touched && invalid)?_c('form-feedback',{staticClass:"form__feedback",attrs:{"content":"form-incomplete"}}):_vm._e(),(_vm.error)?_c('form-feedback',{staticClass:"form__feedback",attrs:{"content":"form-error","icon":"alert"}}):_vm._e()],1)])])]}}],null,false,3999933464)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }